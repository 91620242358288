import Services from "services"

class BDPProduksiApi {
  get(params) {
    return Services.get("/bdp/page", { params })
  }

  getRealisasi(params) {
    return Services.get("/bdp/realisasi_page", { params })
  }

  getSingle(params) {
    return Services.get("/bdp/single", { params })
  }

  getSingleRealisasi(params) {
    return Services.get("/bdp/realisasi_single", { params })
  }

  getDetailRealisasi(params) {
    return Services.get("/bdp/realisasi_detail", { params })
  }

  getHistory(params) {
    return Services.get("/bdp/history", { params })
  }

  getBahan(params) {
    return Services.get("/bdp_bahan/page", { params })
  }

  getUpah(params) {
    return Services.get("/bdp_upah/page", { params })
  }

  getAlatMesin(params) {
    return Services.get("/bdp_alat_mesin/page", { params })
  }

  getSubkon(params) {
    return Services.get("/bdp_subkon/page", { params })
  }

  getOverhead(params) {
    return Services.get("/bdp_overhead/page", { params })
  }

  getCOA() {
    return Services.get("/dropdown/coa")
  }

  getNoBahan() {
    return Services.get("/bdp_bahan/no_baru")
  }

  getNoUpah() {
    return Services.get("/bdp_upah/no_baru")
  }

  getNoAlatMesin() {
    return Services.get("/bdp_alat_mesin/no_baru")
  }

  getNoSubkon() {
    return Services.get("/bdp_subkon/no_baru")
  }

  getNoOverhead() {
    return Services.get("/bdp_overhead/no_baru")
  }

  saveBahan(data) {
    return Services.post("/bdp_bahan", data)
  }

  saveUpah(data) {
    return Services.post("/bdp_upah", data)
  }

  saveAlatMesin(data) {
    return Services.post("/bdp_alat_mesin", data)
  }

  saveSubkon(data) {
    return Services.post("/bdp_subkon", data)
  }

  saveOverhead(data) {
    return Services.post("/bdp_overhead", data)
  }
  dropdownFilterItem() {
    return Services.get("/dropdown/item")
  }

  dropdownBUASO(type) {
    return Services.get(`/bdp_${type}/item`)
  }

  export(params, type) {
    return Services.get(`/bdp_${type}/export`, { params })
  }

  async getDrodpownUnitProduksi(params) {
    const fetch = await Services.get("/dropdown/unit_produksi", {
      params
    })

    return fetch?.data?.data.map((val) => ({
      label: val?.nama_unit_produksi,
      value: val?.id_unit_produksi
    }))
  }
}

export default new BDPProduksiApi()
